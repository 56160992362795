import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

type CartReminderProps = {
  totalItems: number;
};

const CartReminder = ({ totalItems }: CartReminderProps) => {
  const [reminderHidden, setReminderHidden] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      hideReminder();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const hideReminder = () => {
    setReminderHidden(true);
  };

  if (totalItems <= 0 || reminderHidden) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.span
        className='header__item_cart_reminder'
        initial={{ opacity: 0, y: -50, x: 30, scale: 0.3 }}
        animate={{
          opacity: 1,
          y: 0,
          x: 0,
          scale: 1,
          transition: { delay: 0.2 },
        }}
        exit={{
          opacity: 0,
          scale: 0.3,
          y: -50,
          x: 30,
          transition: { duration: 0.2 },
        }}
        onClick={hideReminder}
      >
        It seems like you forgot someting.
      </motion.span>
    </AnimatePresence>
  );
};

export default CartReminder;
