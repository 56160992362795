import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import TrashIcon from '../../assets/icons/trash.svg';
import Button from '../shared/Button';

type Props = {
  priceId: string;
  productName: string;
  productImageFluid: FluidObject | undefined;
  amount: number;
  currency: string;
  pricePerProduct: number;
  updateAmount: (priceId: string, amount: number) => void;
  remove: (priceId: string) => void;
};

const CheckoutItem = ({
  priceId,
  productName,
  productImageFluid,
  amount,
  currency,
  pricePerProduct,
  updateAmount,
  remove,
}: Props) => {
  const increaseAmount = () => {
    updateAmount(priceId, amount + 1);
  };

  const decreaseAmount = () => {
    updateAmount(priceId, amount - 1);
  };

  const removeItem = () => {
    remove(priceId);
  };

  const getPrice = () => {
    return `${currency} ${((amount * pricePerProduct) / 100).toFixed(2)}`;
  };

  return (
    <div className='checkout__item_content'>
      {productImageFluid ? (
        <Img className='checkout__image' fluid={productImageFluid} />
      ) : null}
      <div>
        <div>{productName}</div>
        <div className='checkout__price_section'>
          <div className='checkout__amount_controls'>
            <Button onclick={decreaseAmount}>-</Button>
            <span className='checkout__amount'>{amount}</span>
            <Button onclick={increaseAmount}>+</Button>
          </div>
        </div>
        <div className='checkout__item_price'>{getPrice()}</div>
      </div>
      <div className='checkout__delete_button_wrapper'>
        <Button onclick={removeItem}>
          <TrashIcon />
        </Button>
      </div>
    </div>
  );
};

export default CheckoutItem;
