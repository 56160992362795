import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import CartItem from './CartItem';
import { useLogo } from './useLogo';

const Header = () => {
  const logo = useLogo();

  return (
    <header className='header'>
      <div className='header__items'>
        <div className='header__item' />

        <Link to='/' className='header__logo'>
          <Image fluid={logo} alt='' />
        </Link>

        <CartItem />
      </div>
    </header>
  );
};

export default Header;
