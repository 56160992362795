import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      'pk_test_51Hp7mbD993WqTgQcEdlYRo9vf0MPHS7ugsIh2qB2cLNconMnKh0fFRrZnmEuPcgjGXaJA52Guv5BIOI978uUUWje00Z2A4MUwF'
    );
  }
  return stripePromise;
};
