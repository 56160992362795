import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

interface ProductItem {
  id: string;
  name: string;
  metadata: {
    category: string;
  };
  localFiles: {
    childImageSharp: {
      fluid: FluidObject;
    };
  }[];
}

export const useAllProduct = () => {
  const { allStripeProduct } = useStaticQuery(graphql`
    {
      allStripeProduct {
        nodes {
          id
          name
          metadata {
            category
            sizes
          }
          localFiles {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return allStripeProduct.nodes as ProductItem[];
};
