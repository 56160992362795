import React from 'react';
import Layout from './src/components/Layout';

type Props = {
  props: [];
  element: React.ReactNode;
};

const wrapPageElement = ({ props, element }: Props) => (
  <Layout {...props}>{element}</Layout>
);

export default wrapPageElement;
