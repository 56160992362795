import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

interface PriceItem {
  id: string;
  unit_amount: number;
  currency: string;
  product: {
    id: string;
    name: string;
    localFiles: { childImageSharp: { fluid: FluidObject } }[];
  };
}

export const useAllProductPrice = () => {
  const { allStripePrice } = useStaticQuery(graphql`
    {
      allStripePrice(filter: { active: { eq: true } }) {
        nodes {
          id
          unit_amount
          currency
          product {
            id
          }
        }
      }
    }
  `);

  return allStripePrice.nodes as PriceItem[];
};
