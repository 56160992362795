import React, { useState } from 'react';
import CheckoutIcon from '../../../assets/icons/checkout-icon.svg';
import { useCart } from '../../../contexts/cart-context';
import Checkout from '../../Checkout';
import Button from '../../shared/Button';
import CartCounter from './CartCounter';
import CartReminder from './CartReminder';

const CartItem = () => {
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  const hideCheckout = () => {
    setCheckoutVisible(false);
  };

  const showCheckout = () => {
    setCheckoutVisible(true);
  };

  const cartContext = useCart();

  return (
    <>
      <div className='header__item_cart_wrapper'>
        <CartReminder totalItems={cartContext.cart.totalItems} />

        <Button onclick={showCheckout}>
          <button type='button' className='header__item' onClick={showCheckout}>
            <CheckoutIcon />
            <CartCounter totalItems={cartContext.cart.totalItems} />
          </button>
        </Button>
      </div>
      {checkoutVisible ? <Checkout hideCheckout={hideCheckout} /> : null}
    </>
  );
};

export default CartItem;
