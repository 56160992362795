import React, { createContext, FunctionComponent, useState } from 'react';

interface CartItem {
  priceId: string;
  amount: number;
}

interface Cart {
  items: CartItem[];
  totalItems: number;
}

type Context = {
  cart: Cart;
  add: (priceId: string) => void;
  remove: (priceId: string) => void;
  updateAmount: (priceId: string, amount: number) => void;
  clear: () => void;
};

const CartStateContext = createContext<Context | undefined>(undefined);

export const CartProvider: FunctionComponent = ({ children }) => {
  const [cart, setCart] = useState<Cart>({ items: [], totalItems: 0 });

  React.useEffect(() => {
    const cartStorage = localStorage.getItem('cart');

    if (cartStorage) {
      setCart(JSON.parse(cartStorage));
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const getTotalItems = (prevCart?: Cart): number => {
    const currentCart = prevCart || cart;

    if (currentCart.items.length === 0) return 0;

    return (
      currentCart.items
        .map(x => x.amount)
        .reduce((prev, current) => prev + current) || 0
    );
  };

  const add = (id: string) => {
    const itemExists = cart.items.some(x => x.priceId === id);

    if (itemExists) {
      setCart(prevCart => {
        const newCart = prevCart;
        const item = newCart.items.find(x => x.priceId === id);

        if (item) {
          item.amount += 1;
          newCart.totalItems += 1;
        }

        return { ...newCart };
      });
    } else {
      setCart(prevCart => {
        prevCart.items.push({
          priceId: id,
          amount: 1,
        });
        return { ...prevCart, totalItems: getTotalItems(prevCart) };
      });
    }
  };

  const remove = (id: string) => {
    setCart(prevCart => {
      const newCart = prevCart;
      newCart.items = newCart.items.filter(x => x.priceId !== id);
      return { ...newCart, totalItems: getTotalItems(newCart) };
    });
  };

  const updateAmount = (id: string, amount: number) => {
    const itemExists = cart.items.some(x => x.priceId === id);

    if (itemExists && amount) {
      setCart(prevCart => {
        const newCart = prevCart;
        const item = newCart.items.find(x => x.priceId === id);

        if (item) {
          item.amount = amount;
        }

        return { ...newCart, totalItems: getTotalItems(newCart) };
      });
    } else if (itemExists && !amount) {
      remove(id);
    } else {
      add(id);
    }
  };

  const clear = () => {
    setCart({ items: [], totalItems: 0 });
  };

  return (
    <CartStateContext.Provider
      value={{ cart, add, remove, updateAmount, clear }}
    >
      {children}
    </CartStateContext.Provider>
  );
};

export const useCart = () => {
  const context = React.useContext(CartStateContext);

  if (!context) {
    throw new Error('useCart must be inside Provider');
  }

  return context;
};
