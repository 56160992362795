import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import CrossIcon from '../../assets/icons/cross.svg';
import { useCart } from '../../contexts/cart-context';
import '../../styles/components/checkout.scss';
import { useLogo } from '../Layout/Header/useLogo';
import { useAllProduct } from '../ProductList/useAllProduct';
import { useAllProductPrice } from '../ProductList/useAllProductPrice';
import Button from '../shared/Button';
import Title from '../shared/Title';
import CheckoutContent from './CheckoutContent';
import { Item } from './Item.interface';

type Props = {
  hideCheckout: () => void;
};

const Checkout = ({ hideCheckout }: Props) => {
  const cartContext = useCart();
  const logo = useLogo();
  const productData = useAllProduct();
  const pricesData = useAllProductPrice();

  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    const getUnitPrice = (priceId: string) => {
      return pricesData.find(x => x.id === priceId)?.unit_amount || 0;
    };

    const getProduct = (priceId: string) => {
      const productId = pricesData.find(x => x.id === priceId)?.product.id;
      return productData.find(x => x.id === productId);
    };

    const checkoutItems: Item[] = cartContext.cart.items.map(item => ({
      priceId: item.priceId,
      productId: item.priceId,
      productName: getProduct(item.priceId)?.name || '',
      currency: pricesData.find(x => x.id)?.currency || 'CHF',
      amount: item.amount,
      unitPrice: getUnitPrice(item.priceId),
      fluid: getProduct(item.priceId)?.localFiles[0].childImageSharp.fluid,
    }));
    setItems(checkoutItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContext.cart]);

  return (
    <div className='checkout__background'>
      <div className='checkout__content'>
        <div className='checkout__header'>
          <div className='checkout__close_button_wrapper'>
            <Button onclick={hideCheckout} position='left'>
              <CrossIcon />
            </Button>
          </div>

          <Img className='checkout__logo' fluid={logo} />

          <Title type='secondary'>Checkout</Title>
        </div>
        {items.length ? (
          <CheckoutContent items={items} />
        ) : (
          <div className='checkout__error'>
            <span
              className='checkout__emoji'
              role='img'
              aria-label='Crying emoji'
            >
              😢
            </span>
            <p>Cart is empty</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
