import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

const useLogo = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return logo.childImageSharp.fluid as FluidObject;
};

export { useLogo };
