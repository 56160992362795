import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useCart } from '../../contexts/cart-context';
import { getStripe } from '../../utils/stripejs';
import Button from '../shared/Button';
import CheckoutItem from './CheckoutItem';
import { Item } from './Item.interface';

type Props = {
  items: Item[];
};

const CheckoutContent = ({ items }: Props) => {
  const cart = useCart();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getTotal = () => {
    let amount = 0;
    const currency = 'chf';

    if (items.length) {
      amount = items
        .map(x => (x.amount * x.unitPrice) / 100)
        .reduce((prev, current) => prev + current);
    }

    return `${currency} ${amount.toFixed(2)}`;
  };

  const getLineItems = () => {
    return items.map(x => ({
      price: x.priceId,
      quantity: x.amount,
    }));
  };

  const redirectToCheckout = async () => {
    if (!items.length) return;

    setLoading(true);

    const stripe = await getStripe();

    if (stripe) {
      localStorage.setItem('checkout', 'true');
      const { error } = await stripe.redirectToCheckout({
        mode: 'payment',
        lineItems: getLineItems(),
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/`,
      });

      if (error) {
        localStorage.removeItem('checkout');
        setLoading(false);
        setIsError(true);
      }
    } else {
      setLoading(false);
      setIsError(true);
    }
  };

  if (isError) {
    return (
      <div className='checkout__error'>
        <span className='checkout__emoji' role='img' aria-label='Crying emoji'>
          🐛
        </span>
        <p>
          It seems like the checkout failed{' '}
          <span role='img' aria-label='Shrug emoji'>
            🤷‍♀️
          </span>
        </p>
        <p>Please try again later or contact us.</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className='checkout__error'>
        <span role='img' aria-label='Loading' className='checkout__emoji'>
          🌌
        </span>
        <p>Redirecting to checkout ...</p>
      </div>
    );
  }

  return (
    <>
      {items.map(item => (
        <AnimatePresence key={item.priceId}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0, transition: { duration: 2 } }}
          >
            <CheckoutItem
              priceId={item.priceId}
              productName={item.productName}
              productImageFluid={item.fluid}
              amount={item.amount}
              pricePerProduct={item.unitPrice}
              currency={item.currency}
              updateAmount={cart.updateAmount}
              remove={cart.remove}
            />
          </motion.div>
        </AnimatePresence>
      ))}

      <div className='checkout__subtotal text-italic text-uppercase'>
        Subtotal:
        <span>{getTotal()}</span>
      </div>
      <Button
        className='checkout__checkout_button'
        type='primary'
        position='center'
        onclick={redirectToCheckout}
      >
        Checkout
      </Button>
    </>
  );
};

export default CheckoutContent;
