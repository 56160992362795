import clsx from 'clsx';
import React, { FC } from 'react';
import '../../../styles/components/title.scss';

type Props = {
  align?: 'left' | 'center' | 'right';
  type?: 'primary' | 'secondary';
  className?: string;
};

const Title: FC<Props> = ({ children, type, align, className }) => (
  <h1
    style={{ textAlign: align || 'center' }}
    className={clsx(
      'text-uppercase',
      type ? `title__${type}` : 'title__primary',
      className
    )}
  >
    {children}
  </h1>
);

export default Title;
