import React from 'react';
import EmailIcon from '../../../assets/icons/email.svg';
import InstagramIcon from '../../../assets/icons/instagram.svg';
import FooterItem from './FooterItem';

const Footer = () => {
  return (
    <footer className='footer'>
      <ul className='footer__items'>
        <FooterItem>
          <a
            className='footer__link'
            target='__blank'
            href='mailto:info@streetfocus.ch'
          >
            <EmailIcon />
            EMAIL
          </a>
        </FooterItem>
        <FooterItem>
          <a
            className='footer__link'
            target='__blank'
            href='https://www.instagram.com/streetfocus'
          >
            <InstagramIcon />
            INSTAGRAM
          </a>
        </FooterItem>
      </ul>
      <p className='footer__text'>(C) 2020 STREETFOCUS - ALL RIGHT RESERVED.</p>
    </footer>
  );
};

export default Footer;
