import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';

type Props = {
  totalItems: number;
};

const CartCounter = ({ totalItems }: Props) => {
  const controls = useAnimation();

  useEffect(() => {
    if (totalItems > 0) {
      controls.start({
        scale: [1.1, 1],
      });
    }
  }, [controls, totalItems]);

  if (totalItems <= 0) return null;

  return (
    <motion.span
      animate={controls}
      transition={{ duration: 0.2 }}
      className='header__item_cart_counter'
    >
      {totalItems < 100 ? totalItems : '🙂'}
    </motion.span>
  );
};

export default CartCounter;
