import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { FC, MouseEvent } from 'react';
import '../../../styles/components/button.scss';

type Props = {
  onclick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  type?: 'primary' | 'secondary' | 'transparent';
  position?: 'left' | 'center' | 'right';
  className?: string;
};

const Button: FC<Props> = ({
  children,
  onclick,
  type,
  position,
  className,
}) => (
  <div style={{ textAlign: position ?? 'center' }} className='button__wrapper'>
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      className={clsx(
        type ? `button__${type}` : `button__transparent`,
        className
      )}
      onClick={onclick}
      type='button'
    >
      {children}
    </motion.button>
  </div>
);

export default Button;
