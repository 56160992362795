import React from 'react';

type Props = {
  children: JSX.Element;
};

const FooterItem = ({ children }: Props) => (
  <li className='footer__item'>{children}</li>
);

export default FooterItem;
